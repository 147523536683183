.ffooter {
    background-color: #141414;
    color: #fff;
    padding-bottom: 1rem;
}

.ffooter a {
    color: #fff;
    text-decoration: none;
    transition: color 0.3s;
}

.ffooter a:hover {
    color: #cccccc;
}

.fcontainer {
    max-width: 1200px;
    margin: 0 auto;
}

.footer-main{
    display: flex;
    margin-bottom: 1rem;
}

.footer-column {
    flex-basis: 200px;
    flex-grow: 1;
    text-align:center;
}

.footer-heading {
    margin-top: 1rem;
    margin-left: 2rem;
}

.footer-link {
    display: block;
    width: max-content;
    padding: 5px 10px;
    margin: 0 auto;
}

.footer-bottom {
    display: flex;
}

.copyright {
    margin-right: auto;
}

.footer-social {
    font-size: 20px;
    margin-right: 15px;
}

@media only screen and (max-width: 768px){
    .footer-mainx {
        flex-direction: column;
    }
    .footer-socialx {
        font-size: 12px;
        margin-right: 1px;
    }
    .copyright {
        margin-left: 5px;
    }
    .footer-columnx {
        flex-basis: 0px;
        flex-grow: 1;
        text-align:center;
    }
    .footer-heading {
        margin-top: 1rem;
        margin-left: 1rem;
    }
}
