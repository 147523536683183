hbody{
    margin:20px;
    padding:0;
    text-align:center;
  }
  .hcontainer{
    display:grid;
    grid-template-columns: repeat(4,1fr);
    grid-template-rows: repeat(2,1fr);
    grid-gap:5px;
    grid-auto-flow: dense;
  }
  
  .hcontainer2{
    display:grid;
    grid-template-columns: repeat(2,1fr);
    grid-template-rows: repeat(10,1fr);
    grid-gap:10px;
    grid-auto-flow: dense;
  }

  .grid-item.nested-grid {
    display: grid;
    background-color: lightskyblue;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(3, 1fr);   
  }
  
  .grid-item.nested-grid2 {
    display: grid;
    background-color: rgb(235, 135, 250);
    grid-template-columns: repeat(1, 1fr);
    grid-template-rows: repeat(3, 1fr);   
  }
  
  .container3{
    display:grid;
    grid-template-columns: repeat(1,1fr);
    grid-template-rows: repeat(2,1fr);
    grid-gap:10px;
    grid-auto-flow: dense;
  }
  

  .gallery-item{
    width:100%;
    height:100%;
    position:relative;
  }
  
  .gallery-item .image{
    width:100%;
    height:100%;
    overflow:hidden;
  }
  
  .gallery-item .image img{
    width:100%;
    height:100%;
    object-fit: cover;
    object-position:50% 50%;
    cursor:pointer;
    transition:2.5s ease-in-out;
  }
  /*.gallery-item:hover .image img{
    transform:scale(1.2);
  }
  */
  .gallery-item .text{
    opacity:0;
    position:absolute;
    top:50%;
    left:50%;
    transform:translate(-50%,-50%);
    color:#fff;
    font-size:25px;
    pointer-events:none;
    z-index:4;
    transition: .3s ease-in-out;
    -webkit-backdrop-filter: blur(5px) saturate(1.8);
    backdrop-filter: blur(5px) saturate(1.8);
  }
  
  .gallery-item:hover .textxx{
    opacity:1;
    animation: move-down .3s linear;
    padding:1em;
    width:100%;
  }
  
  .w-1{
    grid-column: span 1;
  }
  .w-2{
    grid-column: span 2;
  }
  .w-3{
    grid-column: span 3;
  }
  .w-4{
    grid-column: span 4;
  }
  .w-5{
    grid-column: span 5;
  }
  .w-6{
    grid-column: span 6;
  }
  
  .h-1{
    grid-row: span 1;
  }
  .h-2{
    grid-row: span 2;
  }
  .h-3{
    grid-row: span 3;
  }
  .h-4{
    grid-row: span 4;
  }
  .h-5{
    grid-row: span 5;
  }
  .h-6{
    grid-row: span 6;
  }
  
.img-title {
  text-align: center;
  font-size: 1.6em; 
}
  
.img-title2 {
  text-align: center;
  font-size: 1.6rem; 
}
  
  @media screen and (max-width:500px){
    .container{
        grid-template-columns: repeat(2,1fr);
    }
    .w-2 {
      grid-column:span 2;
    }
    .w-1 {
      grid-column:span 1;
    }
    .toverlay-text {
      position: absolute;
      bottom: 0;
      padding: 5px;
      font-size: .8rem; 
      color: white;
    }
    .toverlay-text4 {
      position: absolute;
      top: 0;
      padding: 3px;
      font-size: .5rem; 
      color: white;
    }
   .img-title {
      text-align: center;
      font-size:small; 
      font-weight: bold;
   }
   .img-title2 {
     text-align: center;
     font-size: 1.0rem; 
     font-weight: bold;
   }
}
  
  
  @keyframes move-down{
  
    0%{
        top:10%;
    }
    50%{
        top:35%;
    }
    100%{
        top:50%;
    }
  }

  /* grid layout *****/
  .xgrid-container {
    display: grid;
    grid-template-columns: auto auto auto auto auto auto;
    gap: 5px;
  }
  
  .xgrid-container > div {
    background-color: rgba(255, 255, 255, 0.8);
    text-align: center;
    padding: 0 0;
    font-size: 30px;
  }
  
  .xgrid-container2 {
    display: grid;
    grid-template-columns: 25% 25% 25% 25%;
    gap: 3px;
  }
  
  .xgrid-container2 > div {
    background-color: rgba(255, 255, 255, 0.8);
    text-align: center;
    padding: 5px 0;
    font-size: 30px;
  }
  
  .xitem1 {
    grid-column-start: 1;
    grid-column-end: 5;
  }
  .xitem2 {
    grid-column-start: 5;
    grid-column-end: 7;
  }
  .xitem3 {
    grid-column-start: 1;
    grid-column-end: 3;
  }
  
  .xitem4 {
    grid-column-start: 3;
    grid-column-end: 5;
  }
  .xitem5 {
    grid-column-start: 5;
    grid-column-end: 7;
    grid-row-start: 2;
    grid-row-end: 4;
  }
  .xitem6 {
    grid-column-start: 1;
    grid-column-end: 3;
  }
  .xitem7 {
    grid-column-start: 3;
    grid-column-end: 5;
  } 
  .xitem8 {
    grid-column-start: 1;
    grid-column-end: 2;
  }
  .xitem9 {
    grid-column-start: 2;
    grid-column-end: 3;
  }
  .xitem10 {
    grid-column-start: 3;
    grid-column-end: 4;
  }
  .xitem11 {
    grid-column-start: 4;
    grid-column-end: 5;
  }

  .toverlay img {
    width: 100%;
    position: relative;
  }

  .toverlayX {
    position: absolute;
    display: flex;
    width: 100%;
    height: 100%;
    padding: 0px;
    top: 0;
    left: 0;
    color: white;
    font-weight: bold;
    text-align: center;  
    /*justify-content: flex-end; */
    background: linear-gradient(to bottom, rgba(255, 255, 255, 0), #1e2024); 
    z-index: 1;
}

.toverlay2 {
    position: absolute;
    display: flex;
    width: 100%;
    height: 100%;
    padding: 0px;
    top: 0;
    left: 0;
    color: green;
    font-weight: bold;
    text-align: center;  
    /*justify-content: flex-end; */
    /* background: linear-gradient(to bottom, rgba(255, 255, 255, 0), #1e2024); */
    z-index: 1;
}

.toverlay img {
  width: 100%;
  position: relative;
}

.toverlay-text {
    position: absolute;
    bottom: 0;
    padding: 5px;
    font-size: 1rem; 
    color: white;
}

.toverlay-text8 {
    position: absolute;
    top: 2px;
    padding: 5px;
    font-size: 1rem; 
    color: white;
}

.toverlay-text7 {
    position: absolute;
    bottom: 5px;
    padding-right: 10px;
    color: white;
    width: 100%;
}

.toverlay-text6 {
    position: absolute;
    top: 50px;
}

.toverlay-text5 {
    position: absolute;
    top: 25px;
    padding: 5px;
    font-size: 0.5rem; 
    color: white;
}

.toverlay-text4 {
    position: absolute;
    top: 2px;
    padding: 5px;
    font-size: 0.35rem; 
    color: white;
}

.toverlay-text2 {
    position: absolute;
    bottom: 0;
    padding: 0px;
    font-size: small; 
    color: white;
}

.toverlay-text3 {
    position: absolute;
    top: 0;
    padding: 0px;
    font-size: large; 
    z-index:4;
    color: red;
}

.gr-container {
  padding: 0px 0;
  display: grid;
}
