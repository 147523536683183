.app-container {
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 1rem;
}

table {
  border-collapse: collapse;
}

/*
  width: 100%;
 */
 th, td {
  border: 1px solid #ffffff;
  text-align: left;
  padding: 8px;
  font-size: 16px;
}

 th {
  background-color: rgb(117, 201, 250);
}

td {
  background-color: rgb(205, 235, 253);
}

/*
.evt_width {
  width: 100%;
}
*/
.tlform {
  display: flex;
  gap: 5px;
}

.tlform td:last-child {
  display: flex;
  justify-content: space-evenly;
}

.tlform * {
  font-size: 16px;
}

.app-container table {
  border-collapse: collapse;
}
/*
  width: 100%;
 
 */
.app-container th {
  background-color: rgb(117, 201, 250);
}

.app-container td {
  background-color: rgb(205, 235, 253);
}

.add-ctrls {
  display: grid;
  grid-template-columns: auto auto;
  background-color: #2196F3;
}

.clihdr {
  display: flex;
  gap: 10px;
}

.newevt {
  font-size: 1.5em;
}

.emsg {
  font-size: 14px;
   color: #D8000C;
}
