@import url("https://fonts.googleapis.com/css2?family=Marcellus&display=swap");

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: "Marcellus", sans-serif;
}

.dd01 {
  position: absolute;
  z-index: 100;
  background-color: white;
  list-style-type:none;
  padding: 0;
  margin-left:10px;
  margin-top:2px;
  font-size: 16px;
}

.svgmain {
  position: relative;
  top: 0px;
  left: 0px;
}

.lu01b {
  margin-left:10px;
  margin-bottom: 20px;
  font-family: 'Roboto', sans-serif;
  font-size: 18px;
  height: 25px;
}


.lu01 {
  margin-left:10px;
  margin-bottom: 0px;
  font-family: 'Roboto', sans-serif;
  font-size: 18px;
  height: 10px;
}

.lu02 {
  font-family: 'Roboto', sans-serif;
  font-size: 14px;
}

.inp01 {
  border-color: rgb(176,224,230, .4);
  border-width: thin;
  font-family: 'Roboto', sans-serif;
  font-size: 16px;
  width: 165px;
  margin-top: 3px;
  margin-bottom: 0px;
}

.topbtns {
  float: right;
}

.tfmt {
  position: absolute;
  z-index: 100;
}

.tab-list {
  border-bottom: 1px solid #ccc;
  padding-left: 0;
  font-weight: bold;
}

.tab-list-item {
  display: inline-block;
  list-style: none;
  margin-bottom: -1px;
  padding: 0.5rem 0.75rem;
  font-weight: bold;
}

.tab-list-active {
  background-color: white;
  border: solid #ccc;
  border-width: 1px 1px 0 1px;
}

table {
  border-collapse: collapse;
}

table, th, td {
  border: 1px solid rgb(189, 189, 189);
}
/*
table tr, td {
  height: 30px;
  width: 30px;
}
*/
.srkBlack {
  fill:black;
  stroke:black;
}

.srkRed {
  fill: rgb(221, 58, 58);
  stroke: rgb(221, 58, 58);
}

.srkPurple {
  fill: rgb(127,0,255);
  stroke: rgb(127,0,255);
}

.srkGreen {
  fill: rgb(20, 148, 20);
  stroke: rgb(20, 148, 20);
}

.srkOrange {
  fill: rgb(253,88,0);
  stroke: rgb(253,88,0);
}

.btnmenu {
  height: 30px;
  width: 130px;
}

.mhdr {
  text-align: center;
  font-size: 22px;
  font-weight: bold;
}

.btnctr {
  text-align: center;
  color: green;
}

.ititle {
  font-family: 'Lancelot', cursive;
}

.mcenter {
  text-align: center;
  font-size: 14px;
  font-weight: bold;
}

.newchartform {
  margin:10px;
  font-size: 1.25rem;
}
.marLR {
  margin-left: 15px;
  margin-right: 20px;
}

.emsg {
  font-size: 14px;
  color: #D8000C;
}
.mpmsg {
  font-size: 14px;
  color: #00000C;
  margin-left: 0px;
}

.gr-container {
  padding: 5px 0;
  display: grid;
}

.bk_lt {
  background: url(bkgd_lt.svg);
  opacity: 0.3;
}

.bkdt_lt {
  opacity: 0.3;
}

.circol {
  flex: 1;
}

.pred_modal {
  width: 100%;
  flex: 1;
}

.tabcol {
  flex: 1;
  /* scrollbar-gutter: auto; */
}

.move-left {
  width: auto;
  box-shadow: none;
}

@media screen and (max-width: 600px) {
  .gr-container {
    display: inline;
  }
}

@media screen and (max-width: 600px) {
  .jjhcontainer {
   background-color: red;
   height: 193vh;
   width: 100vw;
   display: grid;
   grid-template-columns: 1fr;
   grid-template-rows: 1fr 1fr;
  }
  .d1 {
   background-color: lightgreen;
   grid-column: 1 2;
  }
  .d2 {
   background-color: pink;
   grid-column: 1 2;
  }
}
@media screen and (min-width: 600px) {
.jjhcontainer {
   background-color: lightblue;
   height: 93vh;
   width: 100vw;
   display: grid;
   grid-template-columns: 1fr 1fr;
   grid-template-rows: 1fr;
}

.d1 {
   background-color: lightgreen;
   grid-column: 1 2;
}
.d2 {
   background-color: pink;
   grid-column: 2 3;
}
}
/* screens more than 600px wide */
@media screen and (min-width: 600px) {
  .gr-container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    min-height: 80lv;
    max-height: 80lv;
  /*  flex: 1; */
  }
}
/*  opacity: 0.9; */
.sidebar {
  height: 100vh;
  padding-left: 20px;
  background-color: rgb(192,192,192);
  color: white;
  width: 150px;
  position: fixed;
  z-index: 200;
  left: -100%;
  animation: slide-open 0.5s forwards;
}

.sidebar.close {
  left: 0;
  animation: slide-closed 1.0s forwards;
}

@keyframes slide-open {
  100% { left: 0}
}

@keyframes slide-closed {
  100% { left: -100% }
}

.landpg {
  padding-top: 25px;
  width: "1270px";
  height: "620px";
}

.bkgd_dt {
  width: "1972x";
  height: "1801px";
}

.bkgd_mobt {
  width: "1972x";
  height: "1801px";
}

/*  //object-position: -140px 180px; */
.wheel_dt {
  width: "2291px";
  height: "1546px";
}

.wheel_mobl {
  width: "2291px";
  height: "1546px";
}

.title_dt {
  width: "688px";
  height: "201px";
}

.title_mobl {
  width: "688x";
  height: "201px";
}

.img_ctr {
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.mooncol {
    display: flex;
}

.moonmsg {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
}
.grid-container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 20px;
}

.flex-child {
    flex: 1;
    border: 2px solid grey;
}  

.flex-child:first-child {
    margin-right: 20px;
} 
.flex-container {
  display: flex;
  flex-direction: column;
}

.moongrid {
  display: grid;
  grid-template-rows: 1fr 1fr 1fr;
  grid-template-columns: 1fr 1fr;
  grid-gap: 10px;
}

.mooninterps {
  display: grid;
  grid-template-rows: 1fr 1fr;
  grid-template-columns: 1fr 1fr;
  grid-gap: 10px;
}

.mooninterps div {
  padding-left: 8px;
  font-size: 18px;
}

.btnsm {
  min-width: 100px;
}

.expdt {
  font-family: 'Roboto', sans-serif;
  font-size: 1.2em;
}

.expdeg {
  font-family: 'Roboto', sans-serif;
  font-size: 1.3em;
  display: flex;
  flex-wrap : wrap;
  justify-content: space-between;
}

.bk01 {
   font-weight: bold;
}

.newchart {
  font-size: 1.5em;
}

.tlbook {
   margin-left: 10px;
}

.accordion-button {
  font-size: .65em;
  background-color: #fff;
  border: none;
}

.accordion-body {
  font-size: .7em;
  background-color: #fff;
  border: none;
  margin-bottom: .6em;
}

.title-interp,
.is-open {
  font-weight: 600;
  margin-bottom: .2em;
}
.is-open {
  background-color:rgb(174, 214, 239);
}

.title-interp:hover {
  background-color:rgb(174, 214, 239);
}

.body-interp {
  margin-bottom: .6em;
  margin-left: .5em;
}

.evts {
  font-weight: bold;
  font-size: .9em;
  font-style: italic;
}

.title-tline {
  font-weight: 400;
  font-size: 1em;
}

.title-pinterp {
  font-weight: 400;
  font-size: .5em;
  margin-bottom: 1em;
}

.navbar2 {
  background-color: #060b26;
  display: flex;
  z-index: 99;
  height: 7vh;
}

.navbar-title2 {
  background-color: #000;
  color: #fff;
  font-size: 1.2rem;
  text-align: center;
  width: 100%;
}

.page-title {
  background-color: #fff;
  color: #000;
  font-size: 1.2rem;
  text-align: center;
  width: 100%;
}

.menu-bars2 {
  margin-top: 2px;
  margin-left: .6rem;
  font-size: 1.5rem;
  background: none;
  color: #fff;
}
.nav-menu {
  background-color: #060b26;
  width: 250px;
  height: 100vh;
  display: flex;
  justify-content: center;
  position: fixed;
  top: 0;
  left: -100%;
  transition: 850ms;
}

.nav-menu.active {
  left: 0;
  transition: 350ms;
  z-index: 100;
}

.nav-text {
  display: flex-start;
  align-items: center;
  padding: 0px 0px 8px 8px;
  list-style: none;
  height: 35px;
}

.nav-text a {
  text-decoration: none;
  color: #f5f5f5;
  font-size: 24px;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  padding: 0 0px;
  border-radius: 4px;
}

.nav-text a:hover {
  background-color: #1a83ff;
}

.nav-menu-items {
  width: 100%;
}

.navbar-toggle {
  background-color: #060b26;
  width: 100%;
  height: 20px;
  display: flex-start;
  align-items: center;
}

span {
  margin-left: 0px;
}

.sgaparent {
  position: relative;
  top: 0;
  left: 0;
}
.sgabg {
  position: relative;
  top: 0;
  left: 0;
}
.sgaw {
  position: absolute;
  top: 30px;
  left: 0px;
}

.newchartform {
  margin:10px;
  font-size: 1.25rem;
}

.pred-parent {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(3, 1fr);
  grid-column-gap: 5px;
  grid-row-gap: 5px;
}

.dte-parent {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: repeat(3, 1fr);
  grid-column-gap: 0px;
  grid-row-gap: 0px;
  margin: 20px;
}

.dte-label {
  width:50px;
  text-align: right;
  margin-right: 5px;
  display: inline-block;
}

.pred-legend {
  font-weight: bold;
  width: auto;
  margin-left: auto;
  margin-right: auto;
}

.btnctr {
  text-align: center;
  color: green;
}

.pred-btn {
  padding: 4px;
}

#addevt-form {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-flow: row wrap;
  flex-flow: row wrap;
}   
#addevt-form .fRow {
  display: -webkit-flex;
  display: flex;
  flex-flow: row wrap;
  margin: 7px 0px;
}

#nchart {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-flow: row wrap;
    flex-flow: row wrap;
}    

#chartInfo {
  width: 100%;
}

#nchart .fRow {
    display: -webkit-flex;
    display: flex;
    flex-flow: row wrap;
    margin: 7px 0px;
}

#nchart .fBtn {
  justify-content: center;
  align-items: center;
  border: 5px colid green;
  width: 100%;
}

#nchart legend {
  padding: 5px;
  text-align: center;
  font-weight: bold;
}

#nchart label {
display: inline-block;
width: 15%;
text-align: right;
}

#nchart input {
margin-left:5px;
}

.cli-submit {
  margin: 0 auto;
  color: white;
  border: none;
  padding :0.3em 1.2em;
  font-weight:500;
  transition: all 0.2s;
  background-color:#4e9af1;
  border-radius: 4px;
}

#pevt {
  font-weight: bold;
}

.accordion {
  max-width: 600px;
  margin: .5em auto;
}

.accordion-title {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  font-weight: bold;
  cursor: pointer;
  border-radius: 4px;
}

.accordion-title:hover {
  background-color: #cce9f6;
}

.accordion-title,
.accordion-content {
  padding: .2rem;
}

.accordion-title {
  font-size: 1.2rem;
}
.accordion-item {
overflow: auto;
scrollbar-gutter: auto;
}

.accordion-content {
  background-color: #fff;
}

.modal-pred {
  top : 100px;
  left : 50%;
  width : 'auto';
  right : 'auto';
  bottom : 'auto';
  transform : 'translate(-50%, -50%)';
  background-color: '#ededed';
}

.interp {
  margin: 5px 5px 10px 10px; 
}

.evtdates {
  font-size: 1rem;
  font-weight: bold;
  margin-left: .7rem;
}

.lcontainer{
  display:flex;
  flex-direction: row;
  flex-wrap:wrap;
  justify-content: center;
}

.nccontainer > div {
   margin: 6px;
}

.lbutton {
  padding: 5px 10px;
  color: white;
  cursor: pointer;
  border: none;
  background-color: #0dcaf0;
  border-radius: 5px;
}
