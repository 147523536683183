.nccontainer{
    margin-top: 1.25rem;
    display:flex;
    flex-direction: row;
    flex-wrap:wrap;
 }

.nccontainer > div {
   margin: 6px;
}

@media screen and (max-width: 600px) {
 .nccontainer{
    margin-top: 0rem;
    display:flex;
    flex-direction: row;
    flex-wrap:wrap;
 }
}
