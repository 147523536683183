
/* ============ .contact-form CSS grid ============ */

/* contact form CSS grid layout */
.contact-grid {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 35px;
  margin:10px;
}

/* grid item */
.submit-button {
  justify-self: center;
}

@media screen and (min-width: 736px) {
  
  .contact-grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: 40px 40px 140px 40px;
    grid-gap: 1em;
  }

  /* --- grid items --- */
  .comment-box {
    grid-column: 1 / -1;
  }
  
  .submit-button {
    justify-self: start;
  }
  
}

/* ===== form styles ========= */

/* adds space on top for label */
.contact-form {
  margin-top: 30px;
}

/* positioning .form-field relative, so .lable can be positioned on-top (absolute) */
.form-field {
  position: relative;
}

/* label styles */
.label {
  position: absolute;
  left: 0;
  top: -25px;
  line-height: 1.3em;
  font-weight: 400;
  text-transform: capitalize;
  color: #606060;
  cursor: text;
}

.mpinput {
  border: none;
  height: 30px;
  padding: 0 5px;
  width: 100%;
  background-color: rgb(247,247,247);
  color: rgb(85,85,85);
}

/* text area styles */
textarea {
  height: 140px !important;
  padding: 10px 10px;
  
}

.fs {
 border-width:0;
 text-align: center;
}

.cblbl {
  font-weight: 400;
  text-transform: capitalize;
  color: #888;
}

.mpinterp {
  font-family: "Marcellus", sans-serif;
  font-weight: 400;
  font-size: 1em;
  margin-bottom: 1em;
}

.smpinterp {
  font-family: "Marcellus", sans-serif;
  font-weight: 400;
  font-size: 1em;
  margin-bottom: 1em;
}


/* ============ submit button styles ============ */
.btn {
  background-color: rgb(61,197,223);
  color: white;
  border: 2px solid rgb(61,197,223);
  display: inline-block;
  padding: 2px 3px;
  min-width: 200px;
  margin: 0;
  font-weight: 500;
  text-transform: uppercase;
  cursor: pointer;
  transition: all 0.4s ease-in-out;
}

.btn:hover {
  background-color: white;
  color: rgb(61,197,223);
}

.btn:active {
  box-shadow: inset 0 1px 4px rgba(0, 0, 0, 0.6);
}

/* Tabs */
.tabset > label {
  position: relative;
  display: inline-block;
  padding: 15px 15px 25px;
  border: 1px solid transparent;
  border-bottom: 0;
  cursor: pointer;
  font-weight: 600;
}

.tabset > label::after {
  content: "";
  position: absolute;
  left: 15px;
  bottom: 10px;
  width: 90%;
  height: 4px;
  background: #8d8d8d;
}

.tabset > label:hover,
.tabset > input:focus + label {
  color: #06c;
}

.tabset > label:hover::after,
.tabset > input:focus + label::after,
.tabset > input:checked + label::after {
  background: #06c;
}

.tabset > input:checked + label {
  border-color: #ccc;
  border-bottom: 1px solid #fff;
  margin-bottom: -1px;
}

.tab-panels {
  font-size: 1em;
}

.tab-panel {
  padding: 20px 0;
  border-top: 1px solid #ccc;
  margin: 20px;
}

.tabset > input[type="radio"] {
  position: absolute;
  left: -200vw;
}

.tabset .tab-panel {
  display: none;
}

.tabset > input:first-child:checked ~ .tab-panels > .tab-panel:first-child,
.tabset > input:nth-child(3):checked ~ .tab-panels > .tab-panel:nth-child(2),
.tabset > input:nth-child(5):checked ~ .tab-panels > .tab-panel:nth-child(3),
.tabset > input:nth-child(7):checked ~ .tab-panels > .tab-panel:nth-child(4),
.tabset > input:nth-child(9):checked ~ .tab-panels > .tab-panel:nth-child(5),
.tabset > input:nth-child(11):checked ~ .tab-panels > .tab-panel:nth-child(6) {
  display: block;
}

